<template>
  <button
    class="text-primary border text-sm font-medium border-primary p-2 px-6"
    @click="day = getWeekInitialDate"
  >
    Semana Actual
  </button>

  <input
    v-model="day"
    type="date"
    step="7"
    min="1970-01-05"
    class="
      mx-5
      relative
      p-2
      bg-white
      rounded
      border
      outline-none
      text-gray-700
      placeholder-gray-400
      focus:outline-none
    "
  />
  <div class="px-8 mt-5">
    <div class="grid grid-cols-5 font-medium mb-4">
      <div class="text-secondary">Nombre de Actividad</div>
      <div class="text-secondary text-center">Tipo</div>
      <div class="text-secondary text-center">Subcontratista</div>
      <div class="text-secondary text-center">Descripción</div>
      <div class="text-secondary text-center">Fecha de Registro</div>
    </div>
    <div
      v-for="schedule in getScheduleNotCompleted"
      :key="schedule.key"
      class="grid grid-cols-5 mb-4"
    >
      <div class="text-sm text-secondary font-light py-2">
        {{ schedule.name }}
      </div>
      <div class="text-center px-6 text-sm text-secondary font-light py-2">
        {{ schedule.cause?.types?.name }}
      </div>
      <div class="text-center px-6 text-sm text-secondary font-light py-2">
        {{ schedule.cause?.subcontractor?.name }}
      </div>
      <div class="text-center px-6 text-sm text-secondary font-light py-2">
        {{ schedule.cause?.cause }}
      </div>
      <div class="text-center px-6 text-sm text-secondary font-light py-2">
        {{ dayjs(schedule.cause?.createdAt).format("DD/MM/YYYY") }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";

const store = useStore();

const day = ref("");

const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);
const getScheduleNotCompleted = computed(
  () => store.getters.getScheduleNotCompleted
);

const loadScheduleNotCompleted = async (date) => {
  try {
    const weekKey = await store.dispatch("getWeekKeyByDate", { date });
    if (!weekKey) throw new Error("Fecha inválida");

    await store.dispatch("findScheduleNotCompleted", { weekKey });
  } catch (error) {
    console.error(error);
  }
};

watch(
  () => day.value,
  (currentDay, prevDay) => {
    if (currentDay !== prevDay && currentDay)
      loadScheduleNotCompleted(currentDay);
  }
);

const init = async () => {
  day.value = getWeekInitialDate.value;
};

init();
</script>
