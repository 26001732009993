<template>
  <div class="min-h-full">
    <nav
      class="tabs flex flex-row pt-5 border-b px-6 fixed bg-white w-full z-10"
    >
      <button
        class="tab active text-gray-600 py-2 px-6 block hover:text-blue-500"
        :class="
          mode === 'weekly'
            ? 'font-medium border-b-4 border-blue-500 text-blue-500'
            : ''
        "
        @click="mode = 'weekly'"
      >
        Causas de no cumplimiento
      </button>
    </nav>

    <div v-if="getWeekInitialDate" class="p-8 xl:w-full lg:w-screen pt-20">
      <div v-if="mode === 'weekly'">
        <Weekly />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Weekly from "./weekly/Weekly";

const store = useStore();

const mode = ref("weekly");

const getWeekInitialDate = computed(() => store.getters.getWeekInitialDate);

const init = async () => {
  if (!getWeekInitialDate.value)
    await store.dispatch("setCurrentWeekInitialDate");
};

init();
</script>
